import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageSingle from "../components/page-single"
import HomeContact from "../components/home-contact"

const Page = ({ data, pageContext }) => {
  const { currentPost } = data

  return (
    <Layout>
      <SEO post={currentPost} />
      <PageSingle post={currentPost} />
      <HomeContact />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    currentPost: wordpressPage(id: {eq: $id }) {
      ...PageFull
    }
  }
`
