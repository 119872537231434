import React from "react"

const PageSingle = ({ post }) => {
  return (
    <section className="page-single-component">
      <div className="is-padding-horizontal-4-touch is-margin-vertical-10">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-8-tablet">
              <div className="is-margin-bottom-10-touch">

                <div className="is-margin-bottom-6">
                  <h1 className="link-hover has-text-black is-size-1 has-text-weight-bold">
                    {post.title}
                  </h1>
                </div>

                <div className="is-margin-bottom-8">
                  <div className="content" dangerouslySetInnerHTML={{ __html: post.content }} />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default PageSingle
